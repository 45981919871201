
export const AUTH_VALIDATE = {
  ALL: "all",
  AUTH: "auth",
  CUSTOMER: "customer",
  TUTOR: "tutor",
  ADMIN: "admin",
}

export const TEXT_AUTH = {
  "tutor": "Gia sư",
  "customer": "Học viên",
  "student": "Học viên",
  "admin": "Quản trị viên",
}


export const GENDER = {
  "male": "Nam",
  "female": "Nữ",
  "other": "Khác",
}